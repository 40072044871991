import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '../../state/mainAppState';
import { MagicUseCase } from '../magicUseCase';
import { AddRentalHistoryUseCase } from './application-steps/addRentalHistoryUseCase';
import { GetRentalApplicationStatusUseCase } from './application-steps/getRentalApplicationStatusUseCase';

export class GetRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    const existingApplication = await rentalApplicationGateway.getRentalApplication(credentials);
    const settings = await rentalApplicationGateway.getApplicationSettings();
    if (existingApplication) {
      this.getState().user.rentalApplication.application = existingApplication;
    } else {
      this.getState().user.rentalApplication.application = {
        credentials: credentials,
        newFiles: [],
      };
    }

    if (!existingApplication?.residentialHistory || existingApplication?.residentialHistory.length === 0) {
      new AddRentalHistoryUseCase().execute();
    }

    this.getState().user.rentalApplication.employmentHistoryErrors = [];
    this.getState().user.rentalApplication.rentalHistoryErrors = [];
    this.getState().user.rentalApplication.errors = [];
    this.getState().user.rentalApplication.settings = settings;
    await new GetRentalApplicationStatusUseCase().execute(credentials);
  }
}
